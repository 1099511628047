<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <span class="material-icons close_modal_icon" @click="$emit('close-modal')">close</span>
      </header>
      <section class="modal-card-body p-6">
        <div class="columns is-multiline is-centered">
          <div class="column is-10">
            <img class="img_warning" src="@/assets/warning.png" alt="Warning">
          </div>
          <div class="column is-10">
            <p class="paragraph has-text-centered">
              No es posible eliminar esta Línea ya que tiene productos asociados.
            </p>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button button_micro_dark"
          @click="$emit('close-modal')"
        >
          CERRAR
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalRemoveLine',
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-card {
    border-radius: 18px;
  }
  .modal-card-head {
    justify-content: flex-end;
  }
  .modal-card-head, .modal-card-foot {
    background-color: white;
    border: none;
  }
  .modal-card-foot {
    border-top: none;
    justify-content: center;
  }

  .button {
    font-size: 14px;
    width: 30%;
  }

  .img_warning {
    width: 90px;
    margin: auto;
    display: block;
  }
</style>
